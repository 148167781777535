<template>
	<div id='reset-page' class="form-box row">
		<div class="col">
			<h1>Reset your password:?</h1>
			<form>
				<div class="">
					<input type="password" class="form-control dark-input" placeholder="New password">
				</div>
				<div class="">
					<input type="password" class="form-control dark-input" placeholder="Repeat password">
				</div>
				<button type="button" class="btn btn-warning btn-lg btn-block">Change Password</button>
			</form>
		</div>
	</div>
</template>
<script>
export default {
	mounted() {
		var windowH = $(window).height() - 200 ;
		$('#reset-page').height(windowH)
	}
}
</script>
<style lang='scss' scoped>
#reset-page {
	margin-top: 30%;
}
</style>